import React from "react";
import { Modal, Table, Button } from "antd";

const { Column } = Table;

const WarehouseUnitsPhotoStatsModalComponent = ({
  visible,
  onClose,
  title,
  data = [],
  location,
}) => {
  const renderBrandAndModel = (row) => {
    return row?.car ? `${row.car.model.name} ${row.car.model.trim}` : null;
  };

  const renderAdviser = (item) => {
    const adviser = item?.car?.adviser;
    return adviser ? `${adviser.first_name} ${adviser.last_name}` : null;
  };

  const renderLastUpload = (item) => {
    const media = item?.medias?.[0];
    return media ? `${media.updated_at}` : "N/A";
  };

  return (
    <Modal
      title={title}
      open={visible}
      closable={true}
      onCancel={onClose}
      centered
      width={900}
      footer={null}
    >
      <Table dataSource={data} size="small" bordered pagination={false} rowKey="id">
        <Column title="Id" dataIndex="id" />
        <Column title="Plate Number / Conduction Sticker" dataIndex="slug" />
        <Column title="Color" dataIndex={["car", "color", "specific_name"]} />
        <Column title="Year" dataIndex={["car", "year_model"]} />
        <Column title="Brand & Model" render={renderBrandAndModel} />
        <Column title="Location" render={() => location} />
        <Column title="Adviser" render={renderAdviser} />
        <Column title="Last Upload" render={renderLastUpload} />
      </Table>
    </Modal>
  );
};

export default WarehouseUnitsPhotoStatsModalComponent;
