import React from "react";
import { Empty, Card, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

export const WarehouseSpecialistsComponent = ({ specialists = [] }) => {
  const { Meta } = Card;
  const { Title } = Typography;
  const gridStyle = {
    width: "20%",
    textAlign: "left",
  };

  return (
    <>
      <Title level={5}>Adviser(s)</Title>
      {specialists.length > 0 ? (
        <Card bordered={false} size="small">
          {specialists.map((info, index) => (
            <Card.Grid key={index} style={gridStyle}>
              <UserOutlined /> {`${info.user.first_name} ${info.user.last_name}`}
              <Meta description={info.user.email} />
            </Card.Grid>
          ))}
        </Card>
      ) : (
        <Empty description="No Advisers assigned for this warehouse" />
      )}
    </>
  );
};
