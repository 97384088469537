import { useEffect, useState } from "react";

let initialPermissions = {
  CREATE_WAREHOUSE: 0,
  UPDATE_WAREHOUSE: 0,
  CLEAR_WAREHOUSE: 0,
  DELETE_WAREHOUSE: 0,
  UPDATE_SCHEDULE: 0,
  UPDATE_ADVISERS: 0,
  GENERATE_WAREHOUSE_REPORT: 0,
};

const useGetPermissions = () => {
  const [userPermissions, setUserPermissions] = useState(initialPermissions);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const requiredPermissions = [
      "CREATE_WAREHOUSE",
      "UPDATE_WAREHOUSE",
      "CLEAR_WAREHOUSE",
      "DELETE_WAREHOUSE",
      "UPDATE_SCHEDULE",
      "UPDATE_ADVISERS",
      "GENERATE_WAREHOUSE_REPORT",
    ];

    const permissionObj = permissions.reduce((acc, { name, has_access }) => {
      if (requiredPermissions.includes(name)) {
        acc[name] = has_access;
      }
      return acc;
    }, {});

    setUserPermissions(permissionObj);
  }, []);

  return userPermissions;
};

export default useGetPermissions;
