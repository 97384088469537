import React, { useState, useEffect } from "react";
import { Table, Typography } from "antd";

export const WarehouseAdviserUploadingTrackerComponent = ({ adsWithPhotos, adsWithoutPhotos }) => {
  const { Column } = Table;
  const { Title } = Typography;

  const combinedAdsPhotosList = [...adsWithPhotos, ...adsWithoutPhotos]
    .map((item) => {
      return {
        key: item?.car?.adviser?.id,
        adviserId: item?.car?.adviser?.id,
        adviserName: `${item?.car?.adviser?.first_name || ""} ${
          item?.car?.adviser?.last_name || ""
        }`.trim(),
      };
    })
    .filter((item, index, self) => index === self.findIndex((t) => t.adviserId === item.adviserId));

  for (const item of combinedAdsPhotosList) {
    const { adviserId } = item;

    const filteredAdsWithPhotos = adsWithPhotos.filter((item) => {
      return item?.car?.adviser?.id === adviserId;
    });

    const filteredAdsWithoutPhotos = adsWithoutPhotos.filter((item) => {
      return item?.car?.adviser?.id === adviserId;
    });

    item["uploaded"] = filteredAdsWithPhotos.length;
    item["forUploading"] = filteredAdsWithoutPhotos.length;
    item["total"] = item?.uploaded + item?.forUploading;
  }
  return (
    <>
      <Title level={5}>Uploading Summary</Title>
      <Table dataSource={combinedAdsPhotosList} size="small" bordered pagination={false}>
        <Column title="Adviser" dataIndex="adviserName" key="adviserName" />
        <Column title="Uploaded" dataIndex="uploaded" key="uploaded" />
        <Column title="For Uploading" dataIndex="forUploading" key="forUploading" />
        <Column title="Total" dataIndex="total" key="total" />
      </Table>
    </>
  );
};
