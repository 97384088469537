export const returnBulkUploaderColumnNamesList = () => {
  return [
    "date_updated",
    "number",
    "Conduction_sticker",
    "Plate_number",
    "vehicle_year",
    "vehicle_brand",
    "vehicle_model",
    "vehicle_trim",
    "Fuel_type",
    "Transmission_type",
    "Displacement",
    "Category",
    "Sub Category",
    "Vehicle_Inspection_score",
    "Vehicle_viewing_location",
    "Vehicle_comments",
    "Vehicle_running_condition",
    "Vehicle_Certificate_Registration",
    "Vehicle_OR",
    "Vehicle_place_registration",
    "Plate_availability",
    "Plate_type",
    "Vehicle_color",
    "Veh_odometer",
    "Seller_notes",
    "Auction_start_date",
    "Auction_end_date",
    "Auction_duration",
    "Auction_relist_times",
    "Auction_relist_duration",
    "Auction_start_price",
    "Auction_buy_now_price",
    "Manual_close",
    "Featured",
    "Manual_suspend?",
    "Seller_Institution",
    "Seller Type",
    "Seller_Person_In_Charge",
    "LOAN_PROGRAMME or LOAN_ID",
    "warehouse_id",
    "auction_buy_now_type",
    "installment_providers",
    "tags",
    "is_sold",
    "Auction_Type",
    "Auction_BuyNow_Only",
    "Auction_reserve_price",
    "seller_cellphone",
    "seller_landline",
    "seller_email",
    "Auction_Number",
    "Installment_available?",
    "Installment_DP",
    "Installment_Terms",
    "other_buying_info",
    "Location_open_hours",
    "Location_close_hours",
    "Location_Open_days",
    "Location Address",
    "Landmarks",
    "Location_Notes",
    "location_unitno",
    "location_streetname",
    "location_barangay",
    "location_city",
    "seller_comments",
    "location_province",
    "installment_monthly",
    "status",
    "is_acuv",
    "certification",
    "is_repoz",
    "zero_dp_provider",
    "financing_provider",
  ];
};
