import { useState } from "react";
import { useFetch } from "../useFetch";

const useFetchAdsWithPhotosPerWarehouse = () => {
  const [data, setData] = useState([]);
  const [warehouseLocation, setWarehouseLocation] = useState("");

  const useFetchAdsWithPhotos = async (warehouseId) => {
    const response = await useFetch(
      `${process.env.REACT_APP_API_URL}/ads/with-photo/${warehouseId}`
    );

    if (response) {
      setData(response);
    }
  };

  return {
    adsWithPhotos: data,
    warehouseLocationWithPhoto: warehouseLocation,
    fetchAdsWithPhotos: useFetchAdsWithPhotos,
    setWarehouseWithPhotoLocation: setWarehouseLocation,
  };
};

export default useFetchAdsWithPhotosPerWarehouse;
