import React from "react";
import { Card, Statistic, Button, Typography } from "antd";
import {
  FileImageOutlined,
  FileUnknownOutlined,
  CheckCircleOutlined,
  CarOutlined,
  DollarOutlined,
  EyeOutlined,
} from "@ant-design/icons";

export const WarehouseAdsInformationComponent = ({
  onClickViewUnitsWithoutPhoto,
  onClickViewUnitsWithPhoto,
  location,
  warehouseId,
  totalItems = 0,
  totalBuyNowUnits = 0,
  unitsWithPhoto = 0,
  unitsWithoutPhoto = 0,
  totalActiveBids = 0,
}) => {
  const { Title } = Typography;
  const gridStyle = {
    width: "20%",
    textAlign: "left",
  };

  return (
    <>
      <Title level={5}>Unit(s) Summary</Title>
      <Card bordered={false} size="small">
        <Card.Grid hoverable={true} key="ads_count" style={gridStyle}>
          <Statistic
            title="Total Items"
            value={totalItems}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<CarOutlined />}
          />
        </Card.Grid>
        <Card.Grid hoverable={true} key="active_biddings" style={gridStyle}>
          <Statistic
            title="Active Biddings"
            value={totalActiveBids}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<DollarOutlined />}
          />
        </Card.Grid>
        <Card.Grid hoverable={true} key="buy_now_units" style={gridStyle}>
          <Statistic
            title="Buy Now Units"
            value={totalBuyNowUnits}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<CheckCircleOutlined />}
          />
        </Card.Grid>
        <Card.Grid hoverable={true} key="units_with_photo" style={gridStyle}>
          <Statistic
            title="Units With Photo"
            value={unitsWithPhoto}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<FileImageOutlined />}
          />
          <Button
            size="small"
            type="outline"
            icon={<EyeOutlined />}
            disabled={totalItems > 0 ? (unitsWithPhoto > 0 ? false : true) : true}
            onClick={() => onClickViewUnitsWithPhoto(warehouseId, location)}
          >
            View List
          </Button>
        </Card.Grid>
        <Card.Grid hoverable={true} key="units_without_photo" style={gridStyle}>
          <Statistic
            title="Units Without Photo"
            value={unitsWithoutPhoto}
            valueStyle={{ color: "#2a2d4c" }}
            prefix={<FileUnknownOutlined />}
          />
          <Button
            size="small"
            type="outline"
            icon={<EyeOutlined />}
            disabled={unitsWithoutPhoto > 0 ? false : true}
            onClick={() => onClickViewUnitsWithoutPhoto(warehouseId, location)}
          >
            View List
          </Button>
        </Card.Grid>
      </Card>
    </>
  );
};
