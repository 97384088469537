import { useState } from "react";

const useUnitsWithPhoto = (fetchAdsWithPhotos) => {
  const [isUnitsWithPhotoModalVisible, setIsUnitsWithPhotoModalVisible] =
    useState(false);
  const [warehouseWithPhotoLocation, setWarehouseWithPhotoLocation] =
    useState("");

  const toggleUnitsWithPhotoModal = async (warehouseId, location) => {
    setIsUnitsWithPhotoModalVisible((prev) => !prev);
    await fetchAdsWithPhotos(warehouseId);
    setWarehouseWithPhotoLocation(location);
  };

  const closeUnitsWithPhotoModal = () => {
    setIsUnitsWithPhotoModalVisible(!isUnitsWithPhotoModalVisible);
  };

  return {
    isUnitsWithPhotoModalVisible,
    warehouseLocationWithPhoto: warehouseWithPhotoLocation,
    toggleUnitsWithPhotoModal,
    setIsUnitsWithPhotoModalVisible,
    closeUnitsWithPhotoModal,
  };
};

export default useUnitsWithPhoto;
