import React, { useState } from "react";
import { Table, Tag, Switch } from "antd";
import { CompassFilled } from "@ant-design/icons";
import { WarehouseSpecialistsComponent } from "./WarehouseSpecialistsComponent";
import { WarehouseAdsInformationComponent } from "./WarehouseAdsInformationComponent";
import { WarehouseAdviserUploadingTrackerComponent } from "./WarehouseAdviserUploadingTrackerComponent";

const { Column } = Table;
export const WarehouseTableComponent = ({
  data,
  onChange,
  tableLoading,
  meta,
  selectedRowKeys,
  onPaginationChange,
  onClickViewUnitsWithoutPhoto,
  onClickViewUnitsWithPhoto,
  onChangeFinancingStatus,
  adsWithPhotos,
  adsWithoutPhotos,
  fetchAdsWithPhotos,
  fetchAdsWithoutPhotos,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const combinedAdsPhotosList = [...adsWithPhotos, ...adsWithoutPhotos]
    .map((item) => {
      return {
        key: item?.car?.adviser?.id,
        adviserId: item?.car?.adviser?.id,
        adviserName: `${item?.car?.adviser?.first_name || ""} ${
          item?.car?.adviser?.last_name || ""
        }`.trim(),
      };
    })
    .filter((item, index, self) => index === self.findIndex((t) => t.adviserId === item.adviserId));

  for (const item of combinedAdsPhotosList) {
    const { adviserId } = item;

    const filteredAdsWithPhotos = adsWithPhotos.filter((item) => {
      return item?.car?.adviser?.id === adviserId;
    });

    const filteredAdsWithoutPhotos = adsWithoutPhotos.filter((item) => {
      return item?.car?.adviser?.id === adviserId;
    });

    item["uploaded"] = filteredAdsWithPhotos.length;
    item["forUploading"] = filteredAdsWithoutPhotos.length;
    item["total"] = item?.uploaded + item?.forUploading;
  }

  return (
    <>
      <Table
        loading={tableLoading}
        size={"small"}
        dataSource={data}
        bordered={true}
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          onChange: onChange,
        }}
        pagination={{
          total: meta?.total,
          onChange: onPaginationChange,
          current: meta?.page,
        }}
        scroll={{ x: 1550 }}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: ({
            specialists,
            total_items,
            total_buy_now_units,
            units_with_photo,
            units_without_photo,
            total_active_bids,
            location,
            id,
          }) => (
            <div style={{ padding: "2%" }}>
              <WarehouseAdsInformationComponent
                totalItems={total_items}
                totalBuyNowUnits={total_buy_now_units}
                unitsWithPhoto={units_with_photo}
                unitsWithoutPhoto={units_without_photo}
                totalActiveBids={total_active_bids}
                location={location}
                onClickViewUnitsWithoutPhoto={onClickViewUnitsWithoutPhoto}
                onClickViewUnitsWithPhoto={onClickViewUnitsWithPhoto}
                adsWithPhotos={adsWithPhotos}
                warehouseId={id}
              />
              <br />
              <WarehouseAdviserUploadingTrackerComponent
                adsWithPhotos={adsWithPhotos}
                adsWithoutPhotos={adsWithoutPhotos}
              />
              <br />
              <WarehouseSpecialistsComponent specialists={specialists} />
            </div>
          ),
          onExpand: (expanded, record) => {
            setExpandedRowKeys(expanded ? [record.key] : []);
            fetchAdsWithPhotos(record.id);
            fetchAdsWithoutPhotos(record.id);
          },
        }}
      >
        <Column title="Id" dataIndex="id" key="id" />
        <Column title="Viewing Address" dataIndex="viewing_address" key="viewing_address" />
        <Column
          title="Barangay"
          dataIndex={["barangay", "barangay_name"]}
          key={["barangay", "barangay_name"]}
        />
        <Column title="City" dataIndex={["city", "city_name"]} key={["city", "city_name"]} />
        <Column
          title="Province"
          dataIndex={["province", "province_name"]}
          key={["province", "province_name"]}
        />
        <Column title="Landmark" dataIndex="landmark" key="landmark" />

        <Column
          title="Google Map Uri"
          dataIndex="direction_here"
          key="direction_here"
          width={"10%"}
          render={(direction_here) => (
            <Tag
              className="warehouse-google-map-tag"
              icon={<CompassFilled color="red" />}
              color="green"
              style={{ color: "darkgoldenrod" }}
              href={direction_here}
              onClick={() => {
                window.open(direction_here);
              }}
            >
              Google Maps
            </Tag>
          )}
        />
        <Column title="Days Open" dataIndex="days_open" key="days_open" />
        <Column title="Hours Open" dataIndex="hours_open" key="hours_open" />
        <Column title="Bank" dataIndex={["bank", "name"]} key={["bank", "name"]} />
        <Column
          title="Provider"
          dataIndex={["provider", "provider_name"]}
          key={["provider", "provider_name"]}
        />
        <Column
          title="Has Financing?"
          dataIndex="has_financing"
          key="has_financing"
          render={(has_financing, record) => (
            <Switch
              className="warehouse-financing-switch"
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={has_financing === 1 ? true : false}
              onChange={(switchValue) =>
                onChangeFinancingStatus(has_financing, record, switchValue)
              }
            />
          )}
        />
        <Column title="Date Created" dataIndex="created_at" key="created_at" />
      </Table>
    </>
  );
};
