import { useState } from "react";

const useUnitsWithoutPhoto = (fetchAdsWithoutPhotos) => {
  const [isUnitsWithoutPhotoModalVisible, setIsUnitsWithoutPhotoModalVisible] =
    useState(false);
  const [warehouseWithoutPhotoLocation, setWarehouseWithoutPhotoLocation] =
    useState("");

  const toggleUnitsWithoutPhotoModal = async (warehouseId, location) => {
    setIsUnitsWithoutPhotoModalVisible((prev) => !prev);
    await fetchAdsWithoutPhotos(warehouseId);
    setWarehouseWithoutPhotoLocation(location);
  };

  const closeUnitsWithoutPhotoModal = () => {
    setIsUnitsWithoutPhotoModalVisible(!isUnitsWithoutPhotoModalVisible);
  };

  return {
    isUnitsWithoutPhotoModalVisible,
    warehouseLocationWithoutPhoto: warehouseWithoutPhotoLocation,
    toggleUnitsWithoutPhotoModal,
    setIsUnitsWithoutPhotoModalVisible,
    closeUnitsWithoutPhotoModal,
  };
};

export default useUnitsWithoutPhoto;
