import { useState } from "react";
import { useFetch } from "../useFetch";

const useFetchAdsWithoutPhotosPerWarehouse = () => {
  const [data, setData] = useState([]);
  const [warehouseLocation, setWarehouseLocation] = useState("");

  const useFetchAdsWithoutPhotos = async (warehouseId) => {
    const response = await useFetch(
      `${process.env.REACT_APP_API_URL}/ads/without-photo/${warehouseId}`
    );

    if (response) {
      setData(response);
    }
  };

  return {
    adsWithoutPhotos: data,
    warehouseLocationWithoutPhoto: warehouseLocation,
    fetchAdsWithoutPhotos: useFetchAdsWithoutPhotos,
    setWarehouseWithoutPhotoLocation: setWarehouseLocation,
  };
};

export default useFetchAdsWithoutPhotosPerWarehouse;
